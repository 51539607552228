import React, { Component } from 'react'
import Car from './car'
import SelectpopUp from './select-pop-up-section'
import FormPopUp from './form-pop-up-section'
import ThanksPopUp from './thanks-pop-up-section'
import axios from 'axios';


class CarsSection extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isFormPopUpSubmit: props.isSubmit,
            selectedCar: "",
            selectPopUpEnabled: false,
            formPopUpEnabled: false,
            thanksPopUpEnabled: false,
            cars: props.data.Models,
            selectedCars: props.selectedCars,
            errorMessage: "",
            loading: false,
            generateRecaptchaToken: false,
            buttonOpenFormPopUp: props.buttonOpenFormPopUp,

        };
        this.selectedCarsChanged = this.selectedCarsChanged.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);
    }



    selectedCarsChanged(car, isSelected) {
        this.props.selectedCarsChanged(car, isSelected);
    }


    selectPopUpToggle = (car) => {
        
        if (this.state.selectedCars.length == 3 || car.isSelected === true)
            return
        this.toggleSelectPopUp()
        this.setState({ selectedCar: car })
    }
    toggleSelectPopUp = () => {
        this.setState({ selectPopUpEnabled: !this.state.selectPopUpEnabled });
        //this.props.formPopUpEnabled(this.state.formPopUpEnabled )



    }
    toggleFormPopUp = () => {

        this.state.selectPopUpEnabled ?
            window.document.getElementsByClassName("grecaptcha-badge")[0].classList.add("show") ://show recaptcha badge
            window.document.getElementsByClassName("grecaptcha-badge")[0].classList.remove("show");//hide recaptcha badge


        this.setState({ formPopUpEnabled: false });
        this.props.formPopUpEnabled(false)

    }
    toggleFormPopUpCancel = () => {

        this.setState({ buttonOpenFormPopUp: false })
        this.setState({ formPopUpEnabled: true });
        this.props.toggleFormPopUpCancel()
        this.props.formPopUpEnabled(true)


    }
    toggleThanksPopUp = () => {
        this.setState({ thanksPopUpEnabled: !this.state.thanksPopUpEnabled });

    }
    confirmPopUp = () => {

        this.setState({ errorMessage: "" })
        this.selectedCarsChanged(this.state.selectedCar, true)
        this.toggleSelectPopUp()
        this.setState({ formPopUpEnabled: false });

        this.props.formPopUpEnabled(false)


    }
    setErrorMessage = (errorMessage) => {
        this.setState({ errorMessage: errorMessage });
    };

    formPopUpSubmit = (data) => {
        debugger
        this.setState({ isFormPopUpSubmit: false })
        this.setState({ thanksPopUpEnabled: false });
        this.setState({ generateRecaptchaToken: false })
        // data.SelectedCars=this.state.selectedCars
        axios.post(`${this.props.path + "umbraco/api/CarOfTheYearApi/SetVote"}`, data).then(x => {
            const data = { ...x.data }
            this.setState({ loading: false })
            this.setState({ generateRecaptchaToken: true })
            if (data.IsSuccess) {
                this.setState({ formPopUpEnabled: true })
                this.props.formPopUpEnabled(true)
                this.props.buttonFormPopUp(false, true)
                this.setState({ buttonOpenFormPopUp: false })



                this.setState({ isFormPopUpSubmit: true });

            }
            else if (x.data.Errors.EmailAlreadyExists)
                this.setState({ errorMessage: x.data.Errors.EmailAlreadyExists });

            else if (x.data.Errors.IpAlreadyExists)
                this.setState({ errorMessage: x.data.Errors.IpAlreadyExists });
            else if (x.data.Errors.ErrorSendEmail)
                this.setState({ errorMessage: x.data.Errors.ErrorSendEmail });
            else if (x.data.Errors.ErrorStoreDataOnDb)
                this.setState({ errorMessage: x.data.Errors.ErrorStoreDataOnDb });
            else if (x.data.Errors.PhoneAlreadyExists)
                this.setState({ errorMessage: x.data.Errors.PhoneAlreadyExists });
            else if (x.data.Errors.RecaptchaFailure)
                this.setState({ errorMessage: x.data.Errors.RecaptchaFailure });
            else
                this.setState({ errorMessage: "התרחשה שגיאה, נסה שנית" })
            this.setState({ generateRecaptchaToken: true })




        }).then(this.setState({ loading: true }),   )
            .catch(errorMessage => {
                this.setState({ errorMessage: "הרשת לא זמינה, נסה מאוחר יותר" })
                this.setState({ loading: false });
                return false
            }).then(x => {
            

                this.setState({ loading: false });
            });

    };


    render() {

        this.state.buttonOpenFormPopUp = this.props.buttonOpenFormPopUp

        const car = this.state.cars.map(x => <Car key={x.CmsId} car={x} selectClick={() => this.selectPopUpToggle(x)} path={this.props.path} />)
        const selectPopUp = this.state.selectPopUpEnabled ? <SelectpopUp cancelClick={() => this.toggleSelectPopUp()} confirmClick={() => this.confirmPopUp()} selectedCar={this.state.selectedCar} path={this.props.path} ></SelectpopUp> : null;
        const formPopUp = (this.props.selectedCars.length >= 3 && !this.state.formPopUpEnabled) || this.state.buttonOpenFormPopUp ?
            <FormPopUp cancelClick={() => this.toggleFormPopUpCancel()} formPopUpSubmit={(data) => this.formPopUpSubmit(data)} data={this.props.data} selectedCars={this.state.selectedCars} path={this.props.path} errorMessage={this.state.errorMessage.toString()} loading={this.state.loading} setErrorMessage={this.setErrorMessage} generateRecaptchaToken={this.state.generateRecaptchaToken}> </FormPopUp> : null;
        const thanksPopUp = this.state.isFormPopUpSubmit && !this.state.thanksPopUpEnabled ? <ThanksPopUp cancelClick={() => this.toggleThanksPopUp()} ThanksPageContent={this.props.data.ThanksPageContent} ThanksPageTitle={this.props.data.ThanksPageTitle} path={this.props.path}></ThanksPopUp> : null;

        return (
            <div>
                <section className="cars-section">
                    <div className="container">

                        <div className="cars-grid">
                            {car}
                        </div>

                    </div>

                </section>
                {selectPopUp}
                {formPopUp}
                {thanksPopUp}

            </div>
        )
    }
}

export default CarsSection
