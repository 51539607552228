import React, { Component } from 'react'


class FormPopUpSection extends Component {

  constructor(props) {

    super(props);

    this.state = {

      recaptchaToken: "",
      selectedCars: props.selectedCars,
      isChecked: true,
      isSubmit: false,
      fields: {},
      errors: {},
      errorMessage: props.errorMessage,
      sendButton: "שלח",
      ipCongig: ""
    
    }

  }
  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    this.state.recaptchaToken = recaptchaToken;
  }
  generateRecaptchaToken = () => {
    try {

      window.grecaptcha.execute('6LdNK8MUAAAAADEvEfUksaqivXMDdb69XndygJRw', { action: 'forms' }).then(function (token) {
        window.document.getElementById("hdnRecaptchaToken").value = token;
      });

    } catch (error) {

    }




  };




  componentWillMount() {

    this.props.setErrorMessage("");
    this.generateRecaptchaToken();
    window.document.getElementsByClassName("grecaptcha-badge")[0].classList.add("show")
  }



  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    //  this.handleValidation()
  }

  handleValidation() {
    this.props.setErrorMessage("");
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //All
    if (!fields["name"] && !fields["email"] && !fields["phone"]) {
      formIsValid = false;
      errors["all"] = "יש למלא את שדות החובה";
    }
    else {
      //Name
      if (!fields["name"]) {
        formIsValid = false;
        errors["name"] = "יש להזין שם מלא";
      }

      if (typeof fields["name"] !== "undefined") {
        if (!fields["name"].match(/^[a-zA-Zא-ת]+/)) {
          formIsValid = false;
          errors["name"] = "שם מכיל אותיות בלבד";
        }
      }

      //Email
      if (!fields["email"]) {
        formIsValid = false;
        errors["email"] = "יש להזין כתובת דואר אלקטרוני";
      }

      if (typeof fields["email"] !== "undefined") {
        if (!fields["email"].match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)) {
          formIsValid = false;
          errors["email"] = "כתובת דואר אלקטרוני לא תקינה";
        }

      }
      //Phone
      if (!fields["phone"]) {
        formIsValid = false;
        errors["phone"] = "יש להזין מספר טלפון";
      }

      if (typeof fields["phone"] !== "undefined") {
        if (!fields["phone"].match(/^\d{9,10}$/)) {
          formIsValid = false;
          errors["phone"] = "מספר טלפון לא תקין";
        }
      }
      if (!this.state.isChecked) {
        formIsValid = false;
        errors["checkbox"] = "יש לאשר את תקנון ההשתתפות";

      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {

    this.generateRecaptchaToken();
    //clear server error messages 
    this.props.setErrorMessage("");
    this.setState({ recaptchaToken: window.document.getElementById("hdnRecaptchaToken").value });
    var recaptchaToken = window.document.getElementById("hdnRecaptchaToken").value;


    e.preventDefault();
    if (this.handleValidation()) {

      // const publicIp = require('public-ip');
      // (async () => {
      //  // console.log(await publicIp.v4())
      //   //=> '46.5.21.123'
      // })();
      const localIpUrl = require('local-ip-url');
      debugger
      const data = {
        FullName: this.state.fields["name"],
        Phone: this.state.fields["phone"],
        Email: this.state.fields["email"],
        NewsLetter: this.state.isChecked,
        CampaignName: String(this.props.data.CampaignYear),
        ClientIp: localIpUrl(),
        ReferrerUrl: document.referrer,
        RecaptchaToken: recaptchaToken,
        SelectedCars: this.state.selectedCars,
      }

      this.props.formPopUpSubmit(data);
      if (this.props.generateRecaptchaToken) {
        this.generateRecaptchaToken();
      }

    }



  }


  render() {
    const sendingAnimation = <div className="loader">
      <svg className="car" width="102" height="40" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(2 1)" stroke="#002742" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <path className="car__body" d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01" strokeWidth="3" />
          <ellipse className="car__wheel--left" strokeWidth="3.2" fill="#FFF" cx="83.493" cy="30.25" rx="6.922" ry="6.808" />
          <ellipse className="car__wheel--right" strokeWidth="3.2" fill="#FFF" cx="46.511" cy="30.25" rx="6.922" ry="6.808" />
          <path className="car__line car__line--top" d="M22.5 16.5H2.475" strokeWidth="3" />
          <path className="car__line car__line--middle" d="M20.5 23.5H.4755" strokeWidth="3" />
          <path className="car__line car__line--bottom" d="M25.5 9.5h-19" strokeWidth="3" />
        </g>
      </svg>
    </div>

    return (
      <>
        <section className="pop-up-form-section">
          <div className="dark-wrapper"></div>
          <div className="container">
            <div className="details">
              <h3>{this.props.data.FormTitle}</h3>
              <p> {this.props.data.FormContent} </p>
              <img src={`${this.props.path + this.props.data.FormLogoUrl}`} alt="" />
            </div>
            <form className="form-container" onSubmit={this.contactSubmit.bind(this)} noValidate >
              <input autoFocus className="name" placeholder="שם מלא" type="text" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"] || ''} onBlur={this.handleValidation.bind(this)}  ></input>


              <input className="phone-number" placeholder="מספר טלפון" type="tel" onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"] || ''} onBlur={this.handleValidation.bind(this)}></input>

              <input className="email-address" placeholder="כתובת דואר אלקטרוני" type="email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"] || ''} onBlur={this.handleValidation.bind(this)}></input>

              <label className="label-checkbox-form"><input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} className="checkbox-form" onBlur={this.handleValidation.bind(this)} />
             הנני מאשר את השתתפותי בבחירת אוטו השנה בהתאם <a href="https://www.auto.co.il/article/131360" target="_blank">לתקנון </a>
                <span className="checkmark"></span>
              </label>
              <input type="hidden" id="hdnRecaptchaToken"   ></input>
              <button type="submit" disabled={this.props.loading}>{this.props.loading ? sendingAnimation : this.state.sendButton}</button>


              <label className="error">{this.state.errors["name"]}</label>
              <label className="error">{this.state.errors["email"]}</label>
              <label className="error">{this.state.errors["phone"]}</label>
              <label className="error">{this.state.errors["checkbox"]}</label>
              <label className="error">{this.state.errors["all"]}</label>
              <label className="error" >{this.props.errorMessage} </label>



            </form>
            <button title="Close (Esc)" type="button" className="button-close-popup " onClick={this.props.cancelClick}>×</button>
          </div>


        </section>


      </>
    )
  }
}

export default FormPopUpSection
