import React from 'react'

const Header = (props) => {
    return (

        <header>
            <div className="container">
                <div className="logos">
                    <div className="left-logos ">
                        <a target="_blank" href="https://www.pointer4u.co.il/">
                            <img className="pointer-logo" src={`${props.path + props.leftLogos.Image}`} alt={props.leftLogos.ImageAlt} />
                      </a>

                    </div>

                    <div className="right-logos ">
                        <a target="_blank" href="https://www.mako.co.il/" >
                            <img className="auto-logo" src={`${props.path + props.rightLogos.Image}`} alt={props.rightLogos.ImageAlt} />
                        </a>
                    </div>
                </div>
            </div>
        </header>


    )
}

export default Header
