import React, { Component } from 'react'
import carOfTheYearLogoMobile from '../Images2022/carOfTheYearLogoMobile.png'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MPL4S8D'
}
TagManager.initialize(tagManagerArgs)

class ThanksPopUpSection extends Component {
  constructor(props) {
    super(props);



  }
  render() {
    return (
      <>
        <section className="pop-up-thanks-section">
          <div className="dark-wrapper"></div>
          <div className="container">
            <h3>{this.props.ThanksPageTitle} </h3>
            <p>{this.props.ThanksPageContent}</p>
        


            <button title="Close (Esc)" type="button" className="button-close-popup " onClick={this.props.cancelClick}>×</button>
          </div>


        </section>
      </>
    )
  }
}

export default ThanksPopUpSection
