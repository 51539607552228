import React, { Component } from 'react'
import v from "../Images2022/V.png"
import x from "../Images2022/X.png"


class SelectPopUpSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCar: props.selectedCar,
            selectPopUpEnabled: false,
            formPopUpEnabled: false,
            cars: props.cars,
            selectedCars: props.selectedCars,
            isVoteClosed:true
        };
  
    }
    render() {

        const selectPopUpSection =  <section className="pop-up-select-section">
        <div className="dark-wrapper"></div>
        <div className="container">
            <h1>{this.state.selectedCar.Title}</h1>
            <div className="car-image">
                <img src={`${this.props.path + this.state.selectedCar.Image + '?height=120'}`} alt="" />
            </div>

            <p>{this.state.selectedCar.ShortDescription}</p>
            <div className="bottom-container">
                <h3>זה אוטו השנה שלי</h3>
                <div className="selection-bar">
                    <button className="pop-up-confirm" onClick={this.props.confirmClick}>
                        <img src={v} alt="" />

                    </button>
                    <button className="pop-up-cancel" onClick={this.props.cancelClick} >
                        <img src={x} alt="" />

                    </button>
                </div>
            </div>

        </div>


    </section>
    const   VoteClosedSection =   <section className="pop-up-select-section">
    <div className="dark-wrapper"></div>
    <div className="container">
        <h1>ההצבעה סגורה </h1>
     
        <p>ניפגש בשנה הבאה...</p>
        <div className="bottom-container">
           
            <div className="selection-bar-vote-close">
               
                <button className="pop-up-cancel" onClick={this.props.cancelClick} >
                    <img src={x} alt="" />

                </button>
            </div>
        </div>

    </div>


</section>


const section = !this.state.isVoteClosed ? selectPopUpSection : VoteClosedSection;
        return (
            <>
               {section}
            </>
        )
    }
}

export default SelectPopUpSection
