import React, { Component } from 'react'
import EmptyCar from './empty-car'
import SelectedCars from './selected-car'
class SelectedCarsSection extends Component {

    constructor(props) {
        super(props)

    }

    removeSelection = (x) => {
        this.props.selectedCarsChanged(x, false)
    }
    buttonOpenFormPopUp(){
       this.props.buttonFormPopUp(true,false)
   
    }

    render() {

        this.state = {
            selectedCars: this.props.selectedCars
        };
        let boxItems;
        const totalBoxItems = 1;

        if (this.state.selectedCars != null) {
            boxItems = this.state.selectedCars.map(x => <SelectedCars key={x.CmsId} car={x} removeSelecion={() => this.removeSelection(x)} path={this.props.path}></SelectedCars>);
        }
        //complete empty cars boxes
        // for (let index = 0; index < totalBoxItems - this.state.selectedCars.length; index++) {
        //     boxItems.push(<EmptyCar key={index * 100} ></EmptyCar>)

        // }
        const buttonOpenFormPopUp=this.state.selectedCars.length==3 &&  this.props.formPopUpEnabled ? <div className="OpenFormPopUp"><button className="buttonOpenFormPopUp" onClick={() => this.buttonOpenFormPopUp()} >שלח בחירה</button> </div>:null

        return (



            <>
                {
                    this.state.selectedCars.length > 0 ?
                        <section className="selected-cars-section">
                            <h2>הבחירות שלך לרכב השנה 2023:</h2>
                            <div className="container">
                                <div className="cars-grid">
                                    {boxItems}

                                    
                                </div>
                                {buttonOpenFormPopUp}
                            </div>
                        </section>
                        : null}
            </>
        )
    }
}



export default SelectedCarsSection
