import React from 'react'
import heroMainBackgroundMobile from '../Images2022/mobile.png'
import backgroundDesktop from '../Images2022/desktop.png'
import prodriveLogo from '../Images2022/prodriveLogo.png'
import prodriveMobileLogo from '../Images2022/prodriveMobileLogo.png'


const HeroSection = (props) => {
    return (

        <section className="hero-section">
            <div className="top-content">
                <img src={heroMainBackgroundMobile} className="fade-in mobile-only" alt="" />
                <img src={backgroundDesktop} className="fade-in desktop-only" alt="" />
                <h1>אוטו השנה 2023</h1>
            </div>
            <div className="vote-content">

                <div className="details">
                    <h2>{props.headerTitleText}</h2>
                    <p>{props.headerDescription}
                    
                    </p>
                </div>
            </div>
        </section>

    )
}

export default HeroSection
