import React, { Component } from 'react'


class EmptyCar extends Component {
    state = {

    }

    render() {

        return (


            <div className="car-box empty">

                <div className="car-box-container">

                    <div className="text-container">
                        <div className="text-inner">בחר רכב</div>
                    </div>
                    <div className="car-title">

                    </div>
                </div>

            </div>


        )



    }
}

export default EmptyCar

