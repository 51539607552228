import React, { Component } from 'react'
import Header from './header'
import '../Css/main-page.css'
import HeroSection from './hero-section'
import axios from 'axios';
import CarsContainer from './cars-container';
import DocumentMeta from 'react-document-meta';






class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            path: "https://caroftheyear.auto.co.il/",

            isCorrect: false,
            data: {},
            SetVoteData: {},
            selectedCars: [
            ],
            cars: [],

            meta: {
                title: '',
                description: '',
                canonical: '',

                meta: {
                    charset: '',
                    name: {
                        keywords: ''
                    },

                }
            },

        }
    }



    componentWillMount() {
        axios.get(`${this.state.path + "umbraco/api/CarOfTheYearApi/GetCarOfTheYearData"}`).then(x => {
            this.setState({ data: x.data })
            console.log(x.data)
            this.setState({ isCorrect: true })

            var meta =
            {
                title: x.data.MetaTitle,
                description: x.data.MetaDescription,
                canonical: this.state.path,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: x.data.MetaKeywords
                    },

                }

            }
            this.setState({ meta: meta })




        }).catch(x => {
            alert(x)
        })

    }




    render() {



        return (
            <>

                <DocumentMeta {...this.state.meta}>
                    {this.state.isCorrect ? <div><Header leftLogos={this.state.data.TopLeftLogoImageLink} rightLogos={this.state.data.TopRightLogoImageLink} path={this.state.path}></Header>
                        <HeroSection ProdriveLogoUrl={this.state.data.ProdriveLogoUrl} path={this.state.path} headerDescription={this.state.data.headerDescription} headerTitleText={this.state.data.headerTitleText} ></HeroSection>
                        <CarsContainer data={this.state.data} path={this.state.path}></CarsContainer></div> : null}

                </DocumentMeta>



            </>
        )
    }
}

export default Container
