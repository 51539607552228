import React, { Component } from 'react'


class Car extends Component {


  render() {
    const selected = this.props.car.isSelected ? 'selected' : ''
    const svgSelected = this.props.car.isSelected ? <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="12px">
      <path stroke="rgb(41, 191, 91)" strokeWidth="2px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M3.000,4.000 L6.000,7.000 L12.000,1.000 "></path>
    </svg> : null


    return (
      <div>
        <div className={`car-box ${selected}`} onClick={this.props.selectClick} >
          {svgSelected}
          <div className="image-container">
            <img src={`${this.props.path + this.props.car.Image + '?width=400'}`} alt={this.props.car.alt} />
          </div>
          <div className="car-title">

            <span>{this.props.car.Title}</span>
          </div>
        </div>

      </div>

    )



  }
}

export default Car
