import './App.css';
import './main-page/Css/main-page.css'
import Container from './main-page/Components/container'



function App() {
  
  return (
    <div className="App">

      <Container  ></Container>
     


    </div>
  );
}


export default App;
