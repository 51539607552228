
import React, { Component } from 'react'
import CarsSection from './cars-section'
import SelectedCarsSection from './selected-cars-section';

class CarsContainer extends Component {
    constructor(props) {
        super(props)

        this.selectedCarsChanged = this.selectedCarsChanged.bind(this);

        this.state = {

            SetVoteData: {},
            selectedCars: [],
            cars: props.data.Models,
            buttonOpenFormPopUp:false,


        }
    }
    toggleFormPopUpCancel=()=>{
        this.setState({buttonOpenFormPopUp:false})
    }
    buttonFormPopUp=(buttonOpenFormPopUp,formPopUpEnabled)=>{
        this.setState({buttonOpenFormPopUp:buttonOpenFormPopUp})
        this.setState({formPopUpEnabled:formPopUpEnabled})
    }
    formPopUpEnabled=(e)=>{
        this.setState({formPopUpEnabled:e})
    }

    selectedCarsChanged(car, isSelected) {

        if (!isSelected) //remove shared state
        {
            car.isSelected = isSelected;

            var selectedCarsClone = this.state.selectedCars;

            var carIndex = selectedCarsClone.findIndex(x => x.CmsId === car.CmsId);
            if (carIndex !== -1) {
                selectedCarsClone.splice(carIndex, 1);
                this.setState({ selectedCars: selectedCarsClone });
            }
        }
        //should enter the selected car to state
        else // add to global state
        {
            var selectedCars = this.state.selectedCars;
            var carIndex = selectedCars.findIndex(x => x.CmsId === car.CmsId)
            if (carIndex !== -1)
                return
            car.isSelected = isSelected;
            // var selectedCars = this.state.selectedCars;
            selectedCars.push(car)
            this.setState({ selectedCars: selectedCars });
        }
    }


    render() {
        const selectedCars = this.state.selectedCars;



        return (
            <div>
                <CarsSection selectedCarsChanged={this.selectedCarsChanged} selectedCars={selectedCars} data={this.props.data} path={this.props.path} buttonOpenFormPopUp={this.state.buttonOpenFormPopUp} toggleFormPopUpCancel={() => this.toggleFormPopUpCancel(true)} formPopUpEnabled={(e)=>this.formPopUpEnabled(e)} buttonFormPopUp={() => this.buttonFormPopUp(false,true)} ></CarsSection>
                <SelectedCarsSection selectedCarsChanged={this.selectedCarsChanged} selectedCars={selectedCars} path={this.props.path} buttonFormPopUp={() => this.buttonFormPopUp(true,false)} formPopUpEnabled={this.state.formPopUpEnabled} ></SelectedCarsSection>
            </div>
        );
    }
}



export default CarsContainer